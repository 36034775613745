<template>
  <v-container class="fill-height ma-0 pa-0" fluid>
    <v-card
      class="fill-height container--fluid pa-0 ma-0"
      flat
      tile
      width="100%"
    >
      <v-slide-x-transition appear>
        <v-img
          class="grey lighten-2"
          height="300"
          position="center"
          src="@/assets/images/certified-doctors-only.jpg"
          transition="slide-x-transition"
          width="100%"
        >
          <div
            class="fill-height bottom-gradient container--fluid"
            style="width: 100%"
          >
            <v-container class="fill-height align-end pb-15" fluid>
              <v-row align="end" justify="start">
                <v-col class="pl-5 pl-md-15" cols="12">
                  <v-sheet
                    outlined
                    class="panel-filter-color"
                    rounded="lg"
                    light
                    elevation="10"
                    height="170"
                    :width="$vuetify.breakpoint.xs ? '100%' : 500"
                  >
                    <v-container fluid class="pa-5 ma-5">
                      <v-row align="start" justify="start" dense>
                        <v-col cols="12">
                          <div class="blue--text mx-1">
                            <span class="text-md-h4">
                              The best of Baja California
                            </span>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <div class="indigo--text mx-1">
                            <span class="text-md-h6">
                              We have the best specialists and services high quality. Experienced and Certified Doctors
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row v-show="false" align="start" justify="start">
                        <v-responsive max-width="400">
                          <div class="white--text mx-2">
                            <v-btn
                              class="ma-2 white--text text-body-2"
                              color="cyan"
                              elevation="3"
                              x-large
                            >
                              Make an appointment
                            </v-btn>
                          </div>
                        </v-responsive>
                      </v-row>
                    </v-container>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-img>
      </v-slide-x-transition>
    </v-card>
    <v-container
      class="fill-height justify-center backcolor text-center pa-0 mb-0 mt-10"
      fluid
    >
      <v-row align="center" justify="center">
        <span class="text-h4 font-weight-ligh headline text-uppercase">
          Working together
        </span>
      </v-row>
      <v-row justify="center">
        <div class="boxdivider"></div>
      </v-row>
      <v-row align="center" class="pt-8 pb-8 px-5 px-md-10" justify="center">
        <v-col v-for="(item, index) in cardItems" :key="index" cols="12" md="6">
          <a
            v-bind:href="item.linkextra"
            style="text-decoration: none !important"
          >
            <div class="mx-1 mx-md-5 fill-height fluid">
              <v-img
                :src="item.backimageurl"
                class="rounded-lg elevation-15 align-center"
                height="400px"
                transition="scroll-x-transition"
                width="100%"
              >
                <div
                  class="fill-height align-center fluid blend-opacity"
                  style="height: 400px"
                >
                  <v-layout
                    align-content-center
                    child-flex
                    column
                    fill-height
                    justify-center
                    wrap
                  >
                    <v-flex align-self-center shrink>
                      <span
                        class="white--text font-weight-light pa-2 text-center text-no-wrap text-uppercase"
                      >
                        {{ item.title }}
                      </span>
                    </v-flex>
                    <v-flex align-self-center shrink>
                      <v-btn
                        :to="item.link"
                        class="ma-1"
                        plain
                        text
                        transparent
                      >
                        <span
                          class="white--text text-h4 font-weight-bold mb-3 headline pa-2 text-center text-no-wrap text-uppercase"
                        >
                          {{ item.subtitle }}
                        </span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </div>
              </v-img>
            </div>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "MedicalServicesComponent",
  data: () => ({
    cardItems: [
      {
        backimageurl: require("@/assets/images/doctor-service-final.jpg"),
        title: "Our best",
        subtitle: "Doctors",
        link: "/doctors",
        linkextra: "/doctors",
        flex: 6,
      },
      {
        backimageurl: require("@/assets/images/healthcare-final.jpg"),
        title: "Search for doctors by",
        subtitle: "Specialties",
        link: "/specialties-services",
        linkextra: "/specialties-services",
        flex: 6,
      },
    ],
  }),
};
</script>

<style scoped>
.bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(114, 217, 235, 0.185) 0%,
    rgba(255, 255, 255, 0.356) 100%
  );
}

.panel-filter-color {
  background: rgba(255, 255, 255, 0.767) 80%;
}

.boxdivider {
  background-color: transparent;
  width: 100px;
  height: 20px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}

.backcolor {
  background-color: transparent;
}

.blend-opacity {
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: normal;
}

.blend-opacity:hover {
  opacity: 0.4;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: darken;
}

.zoom-img {
  overflow: hidden;
  border-radius: 10px;
}

.zoom-img > img {
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.zoom-img:hover > img {
  transform: scale(1.05);
}

.zoom-image {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  /* VERZÖGERUNG */
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
}

.zoom-image:hover {
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
  -ms-transform: scale(1.07);
  -o-transform: scale(1.07);
  transform: scale(1.07);
}
</style>
